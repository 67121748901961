.contentLayout {
  height: 100%;
}

.header {
  display: flex;
  align-items: center;
  background-color: #fefefe;
  padding: 8px 16px;
  min-height: 56px;
  max-height: 56px;
  box-shadow: 0px 0.333333px 16px rgba(0, 0, 0, 0.01),
    0px 1.25px 5px rgba(0, 0, 0, 0.06);
  z-index: 99;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  padding: 0 16px 16px 16px;
  overflow: auto;
  background: var(--background);
}

.statusText {
  text-transform: uppercase;
  border-radius: 20px;
}

.filtersContainer {
  margin: 16px 0px;
  padding: 10px 15px;
  background: white;
  box-shadow: 0px 0.333333px 16px rgba(0, 0, 0, 0.01),
    0px 1.25px 5px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.installationFiltersContainer {
  margin: 16px 0px;
  background: white;
  box-shadow: 0px 0.333333px 16px rgba(0, 0, 0, 0.01),
    0px 1.25px 5px rgba(0, 0, 0, 0.06);
  border-radius: 4px;

  :global {
    .ant-collapse-header {
      align-items: baseline !important;
    }
    .ant-collapse-header-text {
      width: 100%;
      flex: inherit !important;
    }
  }
}

.calendarIcon {
  padding-left: 10px;
}

.branch {
  width: 150px;
}

.avatarContent {
  cursor: pointer;
  margin: -5px 1px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 2px;
}

.avatarContent:hover {
  z-index: 1;
}

.plAvatar {
  background-color: var(--color-gray-1);
  color: var(--color-gray-5);
  border: 2px solid #fafafa !important;
}

.selectedPLAvatar {
  background-color: var(--color-blue-primary);
  border-color: var(--color-white) !important;
  color: var(--color-white);
}

.selectedAvatar {
  background-color: var(--color-blue-primary);
  z-index: 1;
}

.checkboxFilters {
  padding-top: 16px;
  display: flex;
  gap: 48px;
  overflow: auto;
}

.teamFilterContainer {
  display: flex;
  justify-content: space-between;
}

.filtersCheckbox {
  margin: 0 16px;
}

.flexJustified {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 236px;
  margin-right: 16px;
}

.compact {
  width: inherit !important;
}

.optionActive {
  border-color: rgba(24, 144, 255, 1);
  color: rgba(24, 144, 255, 1);
}
.optionInactive {
  border-color: rgba(199, 199, 204, 1);
  color: rgba(199, 199, 204, 1);
}

.checkboxDivider {
  height: 2em;
}

.tableContent {
  padding: 16px;
  background: white;
  box-shadow: 0px 0.333333px 16px rgba(0, 0, 0, 0.01),
    0px 1.25px 5px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  width: 100%;
}

.installationTableContent {
  padding: 16px;
  background: white;
  box-shadow: 0px 0.333333px 16px rgba(0, 0, 0, 0.01),
    0px 1.25px 5px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.progressCounter {
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
}

.table {
  :global {
    .ant-table-body {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
    .ant-table-body::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
    .ant-table-thead > tr > th {
      background-color: #fafafa;
    }
  }
}

.tableRowItemHighlighted {
  border-left: 3px solid #f05353;
  background-color: rgb(240, 83, 83, 0.05);
}

.tableTeamNameContainer {
  padding-left: 16px;
}

.isGroupHeader {
  background: var(--color-gray-4);
}

.groupHeaderCell {
  background-color: transparent !important;
  cursor: default;
}
.borderlessCell {
  border-bottom: none !important;
  background: white !important;
}
.shorten {
  padding-right: 32px;
}

.activeColIconsContainer {
  display: flex;
  align-items: center;
  gap: 4px;
}
//saved filters
.saveFilterInputs {
  margin-top: 4px;
  background-color: #fff;
  padding: 12px 6px;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
}
.filterSpace {
  width: 100%;
  margin-top: 4px;
  overflow-y: auto;
  max-height: 220px;
  background-color: #fff;
  padding: 12px 6px 12px 12px;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
}
.saveFilterForm {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.saveFilterInputContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2px 0;
}
.saveFilterButton:focus,
.saveFilterButton:active {
  background-color: transparent !important;
}
.savedFiltersText {
  color: #acacb3;
  font-size: 14px;
  width: 100%;
}
.savedFilterListItem {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.saveFiltersModal {
  border-radius: 6px;
  :global {
    .ant-modal-content {
      padding: 4px 0;
    }
    .ant-modal-header {
      border: none !important;
      padding: 12px 0 8px 20px;
    }
  }
}
.mb16 {
  margin-bottom: 16px !important;
}
.filterModalLink {
  padding-left: 0;
  color: #000;
  border-color: transparent !important;
  box-shadow: none !important;
  max-width: 400px;
  overflow: hidden;
}
.removeFocusColor {
  :global {
    .ant-btn:focus {
      color: #000;
      border-color: #d9d9d9;
    }
  }
}
.indicateSelected {
  color: var(--color-blue-primary);
  border-color: var(--color-blue-primary);
}
.menuItem {
  background-color: white !important;
}
.menuItem:hover {
  background-color: #f5f5f5 !important;
}
.filterIconButton {
  padding: 4px 8px !important;
  div {
    margin-top: 4px;
  }
}
.adjustFilterPopover {
  padding-right: 6px !important;
  :global {
    .ant-popover-inner-content {
      padding: 4px 0 !important;
      //right: -8px;
    }
  }
}

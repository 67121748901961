@import '../../variables';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.commentImage {
  object-fit: cover;
  border: 1px solid var(--color-gray-1);
  height: 80px;
  width: 80px;
}

.loadingBanner {
  position: absolute;
  width: 100%;
  min-height: 100px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hideDisplay {
  position: absolute;
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}

.comments {
  flex: 1 1 auto;
  overflow-y: auto;
}

.buttons {
  .ant-btn {
    background-color: var(--color-green-0);
  }
  margin: 16px;
  display: flex;
  justify-content: space-between;
}

.textArea {
  margin: 16px;
}

.previewCommentText {
  white-space: pre-line;
  word-wrap: break-word;
  width: 100%;
  max-height: 35vh;
  overflow: auto;
}

.twoEyesInfo {
  margin-bottom: 16px;
}

.statusApproved,
.statusApproved:active,
.statusApproved:focus,
.statusApproved:hover {
  background-color: var(--color-green-0);
  border-color: var(--color-green-0);
  color: var(-color-white);
}

.statusRejected,
.statusRejected:active,
.statusRejected:focus,
.statusRejected:hover {
  background-color: var(--color-red-0);
  color: var(--color-white);
}

.statusConfirm,
.statusConfirm:active,
.statusConfirm:focus,
.statusConfirm:hover {
  background-color: var(--color-blue-1);
  border-color: var(--color-blue-1);
  color: var(--color-white);
}

.statusDeny,
.statusDeny:active,
.statusDeny:focus,
.statusDeny:hover {
  background-color: var(--color-blue-2);
  border-color: var(--color-blue-2);
  color: var(--color-white);
}

.statusComment,
.statusComment:active,
.statusComment:focus,
.statusComment:hover {
  border-color: var(--color-blue-primary);
  color: var(--color-blue-primary);
}

.statusApproved,
.statusRejected,
.statusConfirm,
.statusDeny,
.statusComment,
.btnEscalate,
.btnReopen {
  width: 160px;
}

.reasonCodeBox {
  padding: 0 16px;
}

.previewCommentSection {
  margin: 16px;
}
